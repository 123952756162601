import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Does Social Media Management Work?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you are a business owner, you need social media marketing and you know that writing posts, creating graphics, content strategy, answering questions and replying comments take time. That is where social media manager comes in."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Platforms Do You Manage?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you are looking for a social media manager for your social media accounts such as Instagram, Twitter, LinkedIn, Facebook fan page, Facebook group and much more, Codestaff can help you."
        }
    }
}
const SocialMediaManager = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Social Media Managers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Social Media Managers. Top
                                    companies and start-ups choose Codestaff professional Social
                                    Media Managers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Social Media Manager now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE SOCIAL MEDIA MANAGERS' banner='/social-media-manager.png' bannerAlt='social media manager banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default SocialMediaManager